import React from "react";

import "../styles/bulma_loader.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby";

const Oeffnungszeiten = () => {
  return (
    <div className="content">
      <h4 className="title is-4" id="oeffnungszeiten">
        <span className="icon-text">
          <span className="icon">
            <FontAwesomeIcon icon={faClock} />
          </span>
          <span>Öffnungszeiten</span>
        </span>
      </h4>
      <ul>
        <li>Sonntags von 10:00 bis 13:00</li>
        <li>
          Unsere Stände können <b>nur mit Termin</b> genutzt werden
        </li>
        <li>
          <Link to="/Preisliste#title">Preisliste</Link>
        </li>
      </ul>
      <form
        style={{ display: "inline" }}
        action="/buchen"
        method="get"
      >
        <button className="button is-link is-large is-fullwidth mt-3 mb-4">
          <span className="icon">
            <FontAwesomeIcon icon={faCalendarDay} />
          </span>
          <span>Termin buchen</span>
        </button>
      </form>
    </div>
  );
};

export default Oeffnungszeiten;
