import React from "react";

import "../styles/bulma_loader.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

import Obfuscate from "react-obfuscate";

const Schiessanlage = () => {
  return (
    <>
      <h4 className="title is-4">
        <span className="icon-text">
          <span className="icon">
            <FontAwesomeIcon icon={faHome} />
          </span>
          <span>Schießanlage</span>
        </span>
      </h4>
      <p>
        Schützenhaus
        <br />
        Alte Strasse 61
        <br />
        36103 Flieden
      </p>

      <p className="mt-2 mb-2">
        Telefon im Schützenhaus: <Obfuscate tel="06655 25 90" />
      </p>

      <p>
        <a
          href="https://goo.gl/maps/pqQuTLxVBrPfgh4k8"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Maps
        </a>
        (externe Seite)
      </p>
    </>
  );
};

export default Schiessanlage;
