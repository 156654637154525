import React from "react";

import "../styles/bulma_loader.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons";

import Obfuscate from "react-obfuscate";

const Kontakt = () => {
  return (
    <>
      <h4 className="title is-4">
        <span className="icon-text">
          <span className="icon">
            <FontAwesomeIcon icon={faMobileAlt} />
          </span>
          <span>Kontakt</span>
        </span>
      </h4>
      <Obfuscate email="vorstand@sgi-flieden.de" />
      <div className="columns">
        <div className="column">
          <h5 className="subtitle is-5 mb-2 mt-2">1. Vorsitzender</h5>
          <p>
            Fredy Schüßler
            <br />
            Kolpingstr. 25
            <br />
            36119 Neuhof
            <br />
            <Obfuscate tel="06655 74 92 85" />
            <br />
          </p>
        </div>
        <div className="column">
          <h5 className="subtitle is-5 mb-2 mt-2">2. Vorsitzender</h5>
          <p>
            Erwin Haubner
            <br />
            Bornweg 37
            <br />
            63599 Biebergemünd
            <br />
            <Obfuscate tel="06050 90 74 63" />
          </p>
        </div>
      </div>
    </>
  );
};

export default Kontakt;
