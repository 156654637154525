import React from "react";

import "../styles/bulma_loader.scss";
import "../styles/main.scss";

import Layout from "../components/Layout";
import Oeffnungszeiten from "../components/Oeffnungszeiten";
import Kontakt from "../components/Kontakt";
import Schiessanlage from "../components/Schiessanlage";
import BankverbindungUndAdressErfassung from "../components/BankverbindungUndAdressErfassung";

const IndexPage = () => {
  return (
    <Layout>
      <div className="columns features ml-1 mr-1">
        <div className="column is-4">
          <div className="card full-width">
            <div className="card-content">
              <Oeffnungszeiten />
            </div>
          </div>
        </div>
        <div className="column is-4">
          <div className="card full-width">
            <div className="card-content">
              <Schiessanlage />
            </div>
          </div>
        </div>
        <div className="column is-4">
          <div className="card full-width">
            <div className="card-content">
              <Kontakt />
            </div>
          </div>
        </div>
      </div>
      <div className="card full-width block">
        <div className="card-content">
          <BankverbindungUndAdressErfassung />
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
