import React from "react";

import "../styles/bulma_loader.scss";

const BankverbindungUndAdressErfassung = () => {
  return (
    <div className="content is-size-5">
      <h1 className="title" id="bankverbindung">
        Neue Bankverbindung
      </h1>
      <p>
        <em>Liebe Mitglieder,</em>
      </p>
      <p>bitte beachtet, dass sich unsere Bankverbindung ändert:</p>
      <p>
        VR Bank-Main-Kinzig-Büdingen eG
        <br />
        DE40 5066 1639 0004 6924 03
        <br />
        BIC: GENODEF1LSR
      </p>
    </div>
  );
};

export default BankverbindungUndAdressErfassung;
